.project-head{
  color:@primary-color;
  font-size:20px;
  font-weight:bold;
  border-bottom: 1px solid @primary-color;
}

.project-head h2{
  font-weight:bold;
  color:#333;
  font-size: 18px;
  margin:15px 0px 5px 0px;

  @media only screen and (max-width : 480px) {
    text-align:center;
  }
}

.project-head .name,
.project-head .number
{
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 5px;

  @media only screen and (max-width : 480px) {
    margin-top:10px;
    text-align:center;
  }
}

.project-element span{
  color:#777777;
}

.comment span.date,
.comment span.author{
  color:#777777;
}

.project-status{
  padding:15px;
}

.project-status-color(@color) {
  color:@color;
  background-color: lighten(@color, 35%);
}

.project-status-ABANDONED {
  .project-status-color(@color-grey);
}

.project-status-CLOSED {
  .project-status-color(@color-red);
}

.project-status-IN_PROGRESS {
  .project-status-color(@color-blue);
}

.project-status-IN_PROGRESS_CLIENT_VALIDATION {
  background-color: @color-orange;
  color: white;
}

.project-status-VALIDATED {
  .project-status-color(darken(@color-green, 20%));
  color: white;
  font-size: 18px;
}

.project-status-WAITING_FOR_FILE {
  .project-status-color(@color-orange);
}

.project-element-kind-workflow-buttons{
  @media only screen and (max-width : 480px) {
    text-align:center;
  }
}

.nav-wizard > li > a{
  background-color: #fff;
}

.nav-wizard > li:not(:first-child) > a:before {
  border-left: 20px solid #eee;
}

.nav-wizard > li:not(:last-child) > a:after {
  border-left: 20px solid #fff;
}

.nav-wizard > li.done:hover > a:after,
.nav-wizard > li:hover > a:after {
  border-left-color: #d5d5d5;
}

.nav-wizard > li.orange:not(:last-child) > a:after{
  border-left: 20px solid @color-orange;
}

.nav-wizard > li.green:not(:last-child) > a:after{
  border-left: 20px solid @color-green;
}

.nav-wizard > li.blue:not(:last-child) > a:after{
  border-left: 20px solid @color-blue;
}

.nav-wizard > li.purple:not(:last-child) > a:after{
  border-left: 20px solid @color-purple;
}

.nav-wizard > li.pink:not(:last-child) > a:after{
  border-left: 20px solid @color-pink;
}

.nav-wizard > li.yellow:not(:last-child) > a:after{
  border-left: 20px solid @color-yellow;
}

.panel-project > .panel-heading{
  padding: 5px 10px;
  background-color: #eee;
}

.panel-title{
  padding-top: 12px;
  cursor:pointer;
  font-weight: bold;
}

.panel-title .fa{
  position: relative;
  top:-1px;
  margin-right: 5px;
}

.panel-body{
  padding:10px 25px;
}

.panel-body > .error-row{
  padding-top: 5px;
  padding-bottom: 5px;
}

/*
.panel-body > .error-row:nth-child(odd){
  background-color: #eee;
}
*/

.panel-body > .error-row .error-line:nth-child(odd){
  background-color: #eee;
}

.panel-body .error-description{
  padding-bottom:5px;
}

.btn-reporting-validate{
  padding:5px 10px;
  border-radius: 5px;
  background-color: transparent;
  border:2px solid #bbb;
  display: block;
  margin-bottom: 10px;
}

@media (min-width: @screen-sm) {
  .btn-reporting-validate{
    display:inline-block;
    margin-bottom: 0px;
  }
}

.btn-reporting-validate:hover{
  border:2px solid #333;
  background-color: #333;
  color: #fff !important;
}

.btn-reporting-validate:hover span{
  color: #fff !important;
}

.btn-add-comment {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #eee;
  border-radius: 5px;
  font-size: 24px;
  cursor:pointer;
}

.text-element-info {
  font-size: 16px;
  font-weight: bold;
}

.text-element-ok{
  color:@color-green;
}

.text-element-warning {
  color:@color-orange;
}

.btn-validate-file{
  padding:10px 10px;
  border-radius: 5px;
  background-color: @color-green;
  border: 2px solid @color-green;
  color: white;
  display:block;
}

@media (min-width: @screen-sm) {
  .btn-validate-file{
    display:inline-block;
    margin-bottom: 0px;
    padding:5px 10px;
  }
}

.btn-validate-file:hover{
  border-radius: 5px;
  background-color: @color-green;
  border:2px solid @color-green;
  color:#fff;
}

.download-pdf-control{
  color:#000;
  font-size: 16px;
  font-weight: bold;
}

.bar-switch-user{
  background-color: #eee;
  padding-top: 10px;
  padding-bottom: 10px;
}

.bar-switch-user p{
  margin: 0;
  line-height: 30px;
}

.bar-switch-user p.user-back{
  text-align: center;
}

@media (min-width: @screen-sm) {
  .bar-switch-user p.user-back{
    text-align: right;
  }
}

.bar-switch-user .fa-user-secret{
  font-size: 24px;
}
