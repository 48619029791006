// Contextual colors
.text-success {
  .text-emphasis-variant(@success-color);
}

.text-warning {
  .text-emphasis-variant(@warning-color);
}

.text-danger {
  .text-emphasis-variant(@danger-color);
}

.text-red {
  .text-emphasis-variant(@color-red);
}
.text-pink {
  .text-emphasis-variant(@color-pink);
}
.text-purple {
  .text-emphasis-variant(@color-purple);
}
.text-deep-purple {
  .text-emphasis-variant(@color-deep-purple);
}
.text-indigo {
  .text-emphasis-variant(@color-indigo);
}
.text-blue {
  .text-emphasis-variant(@color-blue);
}
.text-light-blue {
  .text-emphasis-variant(@color-light-blue);
}
.text-cyan {
  .text-emphasis-variant(@color-cyan);
}
.text-teal {
  .text-emphasis-variant(@color-teal);
}
.text-green {
  .text-emphasis-variant(@color-green);
}
.text-light-green {
  .text-emphasis-variant(@color-light-green);
}
.text-lime {
  .text-emphasis-variant(@color-lime);
}
.text-yellow {
  .text-emphasis-variant(@color-yellow);
}
.text-amber {
  .text-emphasis-variant(@color-amber);
}
.text-orange {
  .text-emphasis-variant(@color-orange);
}
.text-deep-orange {
  .text-emphasis-variant(@color-deep-orange);
}
.text-brown {
  .text-emphasis-variant(@color-brown);
}
.text-grey {
  .text-emphasis-variant(@color-grey);
}
.text-blue-grey {
  .text-emphasis-variant(@color-blue-grey);
}
.text-black {
  .text-emphasis-variant(@color-black);
}
.text-white {
  .text-emphasis-variant(@color-white);
}
// Text sizes
.text-16{
  font-size: 16px;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: @roboto;
  font-weight: 300;
}
