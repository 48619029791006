@bower-path: "../../../components";

// Bower
@import "@{bower-path}/bootstrap/dist/css/bootstrap.min.css";
@import "@{bower-path}/font-awesome/css/font-awesome.min.css";
@import "@{bower-path}/material-design-iconic-font/dist/css/material-design-iconic-font.min.css";
@import "@{bower-path}/bootstrap-fileinput/css/fileinput.min.css";
@import "@{bower-path}/bootstrap-fileinput/themes/explorer/theme.css";
// @import "@{bower-path}/bootstrap/less/bootstrap.less";
// @import "@{bower-path}/font-awesome/less/font-awesome.less";

@import "@{bower-path}/foxythemes-niftymodals/src/less/style-bootstrap.less";
@import "@{bower-path}/bootstrap-multiselect/dist/less/bootstrap-multiselect.less";

//Config variables
@import "includes/config.less";
@import "includes/bootstrap-variables.less";
@import "includes/boostrap/mixins/badges.less";
@import "includes/boostrap/badges.less";

//Mixins
@import "includes/mixins/background-variant.less";
@import "includes/mixins/triangle.less";
@import "includes/mixins/font-size.less";

//Fonts
@import "includes/fonts/roboto.less";

// Structure
@import "includes/structure/heading.less";

// Elements
@import "includes/elements/colors.less";
@import "includes/elements/panels.less";
@import "includes/elements/nav.less";
@import "includes/elements/types.less";
@import "includes/elements/wizard.less";
@import "includes/elements/forms.less";

// Pages
@import "includes/pages/homepage.less";
@import "includes/pages/page.less";
@import "includes/pages/uploadfile.less";
@import "includes/pages/gabarits.less";
@import "includes/pages/project.less";

// Plugins

// Helper classes
@import "includes/helpers.less";


// Modules
@import "includes/modules/platform/platform.less";

// General
@import "includes/general.less";