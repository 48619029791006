/**** NAV WIZARD - STEP ****/

.nav-wizard{
  display:inline-block;
}

.nav-wizard > li {
  float:none;
  display:inline-block;
  width:100%;
  margin-bottom: 10px;
  padding-right: 10px;
}
@media (min-width: 992px) {
  .nav-wizard > li {
    float: left;
    display:block;
    width:auto;
    margin-bottom: 0px;
    padding-right: 0px;
  }
}
.nav-wizard > li > a {
  position: relative;
  background-color: #eeeeee;
}
.nav-wizard > li > a .badge {
  margin-left: 3px;
  color: @primary-color;
  background-color: #fff;
}
.nav-wizard > li:not(:first-child) > a {
  padding-left: 34px;
}
.nav-wizard > li:not(:first-child) > a:before {
  width: 0px;
  height: 0px;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 20px solid #ffffff;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
}
.nav-wizard > li:not(:last-child) > a {
  margin-right: 6px;
}
.nav-wizard > li:not(:last-child) > a:after {
  width: 0px;
  height: 0px;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 20px solid #eeeeee;
  position: absolute;
  content: "";
  top: 0;
  right: -20px;
  z-index: 2;
}
.nav-wizard > li:first-child > a {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.nav-wizard > li:last-child > a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.nav-wizard > li.done:hover > a,
.nav-wizard > li:hover > a {
  background-color: #d5d5d5;
}
.nav-wizard > li.done:hover > a:before,
.nav-wizard > li:hover > a:before {
  border-right-color: #d5d5d5;
}
.nav-wizard > li.done:hover > a:after,
.nav-wizard > li:hover > a:after {
  border-left-color: #d5d5d5;
}
.nav-wizard > li.done > a {
  background-color: #e2e2e2;
}
.nav-wizard > li.done > a:before {
  border-right-color: #e2e2e2;
}
.nav-wizard > li.done > a:after {
  border-left-color: #e2e2e2;
}

.nav-wizard > li.active > a,
.nav-wizard > li.active > a:hover,
.nav-wizard > li.active > a:focus {
  color: #ffffff;
  background-color: @primary-color;
}
.nav-wizard > li.active > a:after {
  border-left-color: @primary-color;
}
.nav-wizard > li.active > a .badge {
  color: @primary-color;
  background-color: #ffffff;
}

.nav-wizard-li-color(@color) {
  > a,
  > a:hover,
  > a:focus {
    color: #ffffff;
    background-color: @color;
  }
  > a:after {
    border-left-color: @color;
  }
  > a .badge {
    color: @color;
    background-color: #ffffff;
  }
}

.nav-wizard > li.orange{
  .nav-wizard-li-color(@color-orange);
}

.nav-wizard > li.green{
  .nav-wizard-li-color(@color-green);
}

.nav-wizard > li.blue{
  .nav-wizard-li-color(@color-blue);
}

.nav-wizard > li.purple{
  .nav-wizard-li-color(@color-purple);
}

.nav-wizard > li.pink{
  .nav-wizard-li-color(@color-pink);
}

.nav-wizard > li.yellow{
  .nav-wizard-li-color(@color-yellow);
}

.nav-wizard > li.disabled > a {
  color: #777777;
}
.nav-wizard > li.disabled > a:hover,
.nav-wizard > li.disabled > a:focus {
  color: #777777;
  text-decoration: none;
  background-color: #eeeeee;
  cursor: default;
}
.nav-wizard > li.disabled > a:before {
  border-right-color: #eeeeee;
}
.nav-wizard > li.disabled > a:after {
  border-left-color: #eeeeee;
}
.nav-wizard.nav-justified > li {
  float: none;
}
.nav-wizard.nav-justified > li > a {
  padding: 10px 15px;
}
@media (max-width: 768px) {
  .nav-wizard.nav-justified > li > a {
    border-radius: 4px;
    margin-right: 0;
  }
  .nav-wizard.nav-justified > li > a:before,
  .nav-wizard.nav-justified > li > a:after {
    border: none !important;
  }
}

/*
@media only screen and (max-width : 480px) {
  .nav-wizard{
    margin-top: 10px;
  }

  .nav-wizard > li {
    float: left;
    display:block;
    width:auto;
    margin-bottom: 0px;
    padding-right: 0px;
  }

  .nav-wizard > li > a{
    padding:5px 8px;
    font-size:12px;
  }

  .nav-wizard > li:not(:first-child) > a:before {
    border-top-width: 14px;
    border-bottom-width: 14px;
    border-left-width: 14px;
  }
  .nav-wizard > li:not(:last-child) > a {
    margin-right: 6px;
  }
  .nav-wizard > li:not(:last-child) > a:after {
    border-top-width: 14px;
    border-bottom-width: 14px;
    border-left-width: 14px;
    right: -14px;
  }
}

*/
