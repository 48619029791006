.heading{
  margin-top: 10px;
  margin-bottom: 20px;
}

.heading .logo{
  padding-top:10px;
  padding-bottom:10px;
}

.heading .logo img{
  max-height: 40px;
}

.heading-title{
  background-color:#9c9e9f;
  color:#ffffff;
}

.heading-title h2{
  font-size: 24px;
  line-height: 40px;
  padding:10px 15px;
  margin:0;
  text-transform: uppercase;

  @media only screen and (max-width : 480px) {
    font-size:24px;
  }
}

.page-head{
  margin-bottom: 15px;
}

.user-head{
  color:#fff;
  padding:15px 15px;
  line-height: 30px;
  font-weight:bold;
  text-align: right;
}

.user-head a{
  color:#fff;
}
