/*!
 * bootstrap-fileinput v4.5.3
 * http://plugins.krajee.com/file-input
 *
 * Krajee Explorer theme style for bootstrap-fileinput. Load this theme file after loading `fileinput.css`.
 *
 * Author: Kartik Visweswaran
 * Copyright: 2014 - 2019, Kartik Visweswaran, Krajee.com
 *
 * Licensed under the BSD-3-Clause
 * https://github.com/kartik-v/bootstrap-fileinput/blob/master/LICENSE.md
 */
.theme-explorer .file-upload-indicator, .theme-explorer .file-drag-handle, .theme-explorer .explorer-frame .kv-file-content, .theme-explorer .file-actions, .explorer-frame .file-preview-other {
    text-align: center;
}

.theme-explorer .file-thumb-progress .progress, .theme-explorer .file-thumb-progress .progress-bar {
    height: 13px;
    font-size: 11px;
    line-height: 13px;
}

.theme-explorer .file-upload-indicator, .theme-explorer .file-drag-handle {
    position: absolute;
    display: inline-block;
    top: 0;
    right: 3px;
    width: 16px;
    height: 16px;
    font-size: 16px;
}

.theme-explorer .file-thumb-progress .progress, .theme-explorer .explorer-caption {
    display: block;
}

.theme-explorer .explorer-frame td {
    vertical-align: middle;
    text-align: left;
}

.theme-explorer .explorer-frame .kv-file-content {
    width: 80px;
    height: 80px;
    padding: 5px;
}

.theme-explorer .file-actions-cell {
    position: relative;
    width: 120px;
    padding: 0;
}

.theme-explorer .file-thumb-progress .progress {
    margin-top: 5px;
}

.theme-explorer .explorer-caption {
    color: #777;
}

.theme-explorer .kvsortable-ghost {
    opacity: 0.6;
    background: #e1edf7;
    border: 2px solid #a1abff;
}

.theme-explorer .file-preview .table {
    margin: 0;
}

.theme-explorer .file-error-message ul {
    padding: 5px 0 0 20px;
}

.explorer-frame .file-preview-text {
    display: inline-block;
    color: #428bca;
    border: 1px solid #ddd;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
    outline: none;
    padding: 8px;
    resize: none;
}

.explorer-frame .file-preview-html {
    display: inline-block;
    border: 1px solid #ddd;
    padding: 8px;
    overflow: auto;
}

.explorer-frame .file-other-icon {
    font-size: 2.6em;
}

@media only screen and (max-width: 767px) {
    .theme-explorer .table, .theme-explorer .table tbody, .theme-explorer .table tr, .theme-explorer .table td {
        display: block;
        width: 100% !important;
    }

    .theme-explorer .table {
        border: none;
    }

    .theme-explorer .table tr {
        margin-top: 5px;
    }

    .theme-explorer .table tr:first-child {
        margin-top: 0;
    }

    .theme-explorer .table td {
        text-align: center;
    }

    .theme-explorer .table .kv-file-content {
        border-bottom: none;
        padding: 4px;
        margin: 0;
    }

    .theme-explorer .table .kv-file-content .file-preview-image {
        max-width: 100%;
        font-size: 20px;
    }

    .theme-explorer .file-details-cell {
        border-top: none;
        border-bottom: none;
        padding-top: 0;
        margin: 0;
    }

    .theme-explorer .file-actions-cell {
        border-top: none;
        padding-bottom: 4px;
    }

    .theme-explorer .explorer-frame .explorer-caption {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        left: 0;
        right: 0;
        margin: auto;
    }
}

/*noinspection CssOverwrittenProperties*/
.file-zoom-dialog .explorer-frame .file-other-icon {
    font-size: 22em;
    font-size: 50vmin;
}
