.margin-center {
    margin-left: 15%;
}
.circle {
    font-size: 35px;
    margin-top: -40%;
}

.circle-orange {
    color: orange;
}

.circle-green {
    color: green;
}

.circle-red {
    color: red;
}

.uploadfile-form-input-border {
    border-bottom: 1px solid #ddd;
    margin-left: 4%;
    margin-right: 34%;
    margin-bottom: 2%;
}

.drag-drop-file .form-control,
.drag-drop-file-customer-area .form-control{
  height:44px;
}

.drag-drop-file .file-preview,
.drag-drop-file-customer-area .file-preview{
  border:none;
  padding:0;
  margin-bottom: 15px;
}

.drag-drop-file .file-preview .close,
.drag-drop-file-customer-area .file-preview .close{
  margin:5px 10px;
}

.drag-drop-file .file-drop-zone,
.drag-drop-file-customer-area .file-drop-zone{
  margin:0;
}

.title-historique {
    margin-top: 10%;
    font-weight: bold;
    font-size: 16px;
}

.historique-border {
    padding: 1%;
    border-bottom: 2px solid @primary-color;
    margin-bottom: 5%;
}

.historique-item {
    padding: 1%;
    color: green;
}

.historique-item-date {
    font-size: 11px;
    text-align: center;
}

.historique-item-name {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.register_unknown_contact{
  font-weight:bold;
  font-size:16px;
  margin-left:15px;
  padding:5px 0px;
}

.file-drop-zone-title{
  padding: 40px 10px !important;
}

.fileinput-upload-button {
    background-color: @success-color;
}
