/*------------------------------------------------------------------
[General elements style]
*/

html{
  height:100%;
  overflow-x: hidden;
}

body {
  padding: 0;
  background-color: #fff;
  height: 100%;
  font-size: 14px;
}

a{
  color: @brand-primary;
  &:hover, &:focus{
    text-decoration: none;
    outline: none;
  }
}

p{
  font-size: @paragraph-font-size;
  line-height: @paragraph-line-height;
}

//Fix for plugins like charts
canvas{
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

hr{
  border-top: 1px solid #ddd;
}


label.required:after {
  color: red;
  content: " * ";
}
