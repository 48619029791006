.panel-body{
  padding:30px 45px;
}

.panel-heading-icon-title-baseline i{
  color:@primary-color;
  font-size:72px;
}

.panel-heading-icon-title-baseline h2{
  margin-top: 0px;
  color:@primary-color;
}

.panel-heading-icon-title-baseline{
  border-bottom:none;
}

.panel-heading-background-primary{
  background-color:@primary-color !important;
  color:#ffffff !important;
  font-size:18px;
  font-weight:bold;
}

.panel-title{
  margin-bottom: 15px;
}
