/*------------------------------------------------------------------
  [Colors]
*/
.clr-primary{       background-color: @primary-color; }
.clr-success{       background-color: @success-color; }
.clr-warning{       background-color: @warning-color; }
.clr-danger{        background-color: @danger-color; }
.clr-grey{          background-color: @grey-color; }


.bg-primary {
  .bg-variant(@primary-color);
}
.bg-red {
  .bg-variant(@color-red);
}
.bg-pink {
  .bg-variant(@color-pink);
}
.bg-purple {
  .bg-variant(@color-purple);
}
.bg-deep-purple {
  .bg-variant(@color-deep-purple);
}
.bg-indigo {
  .bg-variant(@color-indigo);
}
.bg-blue {
  .bg-variant(@color-blue);
}
.bg-light-blue {
  .bg-variant(@color-light-blue);
}
.bg-cyan {
  .bg-variant(@color-cyan);
}
.bg-teal {
  .bg-variant(@color-teal);
}
.bg-green {
  .bg-variant(@color-green);
}
.bg-light-green {
  .bg-variant(@color-light-green);
}
.bg-lime {
  .bg-variant(@color-lime);
}
.bg-yellow {
  .bg-variant(@color-yellow);
}
.bg-amber {
  .bg-variant(@color-amber);
}
.bg-orange {
  .bg-variant(@color-orange);
}
.bg-deep-orange {
  .bg-variant(@color-deep-orange);
}
.bg-brown {
  .bg-variant(@color-brown);
}
.bg-grey {
  .bg-variant(@color-grey);
}
.bg-blue-grey {
  .bg-variant(@color-blue-grey);
}
.bg-black {
  .bg-variant(@color-black);
}
.bg-white {
  .bg-variant(@color-white);
}
