.home-table .row:first-child{
  /*
  background-image: linear-gradient(to right, white 50%, transparent 50%);
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 20px 3px;
  */
}

.home-table .row .col:first-child{
  /*
  background-image: linear-gradient(to bottom, white 50%, transparent 50%);
  background-position: right top;
  background-repeat: repeat-y;
  background-size: 3px 20px;
  */
}

.home-table .row .col{
  padding-top:15px;
  padding-bottom:15px;
}

.home-table .row .col a{
  font-size:16px;
  display: block;
  border:1px solid #ddd;
  transition: all 0.5s;
  padding:15px;
}

.home-table .row .col a.send-file-link{
  font-size:20px;
}

.home-table .row .col a span{
  text-transform: uppercase;
  color:#333;
}

.home-table .row .col a:hover{
  background-color: @primary-color;
  color:#fff;
  border:1px solid @primary-color;
}

.home-table .row .col a:hover span{
  color:#fff;
}

.home-table .row .col i{
  vertical-align:middle;
  margin-bottom: 8px;
}

.page-head-title{
  text-transform: uppercase;
}
