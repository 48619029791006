.head-nav{
  padding:0;
  margin:0;
  font-size:0;
  border-bottom:4px solid @primary-color;
  background-color: @primary-color;
}

.head-nav li{
  padding:15px;
  display:inline-block;
  text-align:center;
  width:20%;
  min-height: 118px;
  vertical-align: top;
  border-top: 1px solid #C7C7CC;
  border-left: 1px solid #C7C7CC;
  border-right: 1px solid #C7C7CC;
  background-color:#fff;
}
.head-nav li .correction{
  color: #d12f19;
}

.head-nav li.active{
  background-color:@primary-color;
  border: none;
  color: #fff;
  margin-top: -1%;
  z-index: 99;
}

.head-nav li a{
  font-size:14px;
}

.head-nav li a span{
  color:#333;
}

.head-nav li a i{
  font-size:3em;
  display:block;
  margin-bottom: 5px;
}

.head-nav li.active a,
.head-nav li.active a span{
  color:#ffffff;
}

@media only screen and (max-width : @screen-md) {

  .head-nav li{
    min-height: 80px;
  }

  .head-nav li a span{
    display:none;
  }

  .head-nav li a i{
    font-size:2em;
    display:block;
    margin-bottom: 15px;
  }
}
