.p-0{
  padding:11px 0px 0px 0px;
}

.gabarits-header {

}

.gabarits-header-title {
  margin-top: 10%;
  font-size: 26px;
  font-weight: bold;
  color: @primary-color;
}

.form-gabarits{
  padding: 30px 10px;
  border-top: 2px solid @primary-color;
  border-bottom: 2px solid @primary-color;
  margin-bottom: 15px;
}

.form-gabarits input {

}

.form-gabarits select {
  color: @primary-color;
}

.gabarits-bg-blue {
  background-color: rgba(0, 0,  255, 0.2);
  width: 26px;
}
.cote-couv{
  font-size: 18px;
  font-weight: bold;
  color:@primary-color;
}

.gabarits-structure {
  margin-left: 3%;
  position: relative;
  font-size: 12px;
  color: black;
  font-weight: bold;
  text-align: center;
}

.gabarits-corner-left-top-top {
  position: absolute;
  left: 12.5%;
  top: -3%;
}

.gabarits-corner-left-top-left {
  position: absolute;
  top: 9.5%;
}

.gabartis-left-top-middle {
  position: absolute;
  left: 35%;
  top: -3%;
}

.gabarits-middle-top {
  position: absolute;
  left: 58.8%;
  top: -2%;
}

.gabarits-right-top-middle {
  position: absolute;
  right: 15%;
  top: -2%;
}

.gabarits-corner-right-top-top {
  position: absolute;
  top: -2%;
  right: -8.3%;
}

.gabarits-left-middle {
  position: absolute;
  top: 45%;
  left: 1%;

}

.gabarits-left-bottom {
  position: absolute;
  left: 2%;
  bottom: 12.5%;
}

.gabarits-largeur-totale {
  margin-left: 20%;
  margin-top: 2%;
  font-size: 14px;
}

.gabarits-hauteur-totale {
  position: absolute;
  font-size: 14px;
  top: 45%;
  right: -27%;
}

.format-text{
  display: inline-block;
  margin-top: 9px;
  font-size: 13px;
}

.dimension-position {
  position: absolute;
}

.largeur-totale-value,
.hauteur-totale-value {
  color: #3c2d91;
}

.lost-edges,
.edges {
  color: #872996;
}

.cover-1-inside-width,
.cover-4-inside-width,
.hauteur-interieur-value,
.open-width {
  color: #ed2023;
}

.dos-value {
  color: #f68415;
}

.first-flap,
.last-flap {
  color: #613e08;
}

.creasing,
.bit {
  color: #3dae38;
}

.gabarit-col-width{
  margin-top: 5px;
  @media(min-width: 992px){
    padding-right: 10px;
    margin-top: 0;
  }
}

.gabarit-col-height{
  margin-top: 5px;
  @media(min-width: 992px){
    padding-left: 10px;
    margin-top: 0;
  }
}
