.clearfix() {
    &:before,
    &:after {
      content: " "; // 1
      display: table; // 2
    }
    &:after {
      clear: both;
    }
  }

  // Button sizes
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
    padding: @padding-vertical @padding-horizontal;
    font-size: @font-size;
    line-height: @line-height;
    border-radius: @border-radius;
  }

  .alert-variant(@background; @border; @text-color) {
    background-color: @background;
    border-color: @border;
    color: @text-color;
  
    hr {
      border-top-color: darken(@border, 5%);
    }
    .alert-link {
      color: darken(@text-color, 10%);
    }
  }

  // Typography

.text-emphasis-variant(@color) {
    color: @color;
    a&:hover,
    a&:focus {
      color: darken(@color, 10%);
    }
  }