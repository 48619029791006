.foot-table .row:first-child{
  /*
  background-image: linear-gradient(to right, white 50%, transparent 50%);
  background-position: top;
  background-repeat: repeat-x;
  background-size: 20px 3px;
  */
}

.foot-table .row .col{
  /*
  background-image: linear-gradient(to bottom, white 50%, transparent 50%);
  background-position: right top;
  background-repeat: repeat-y;
  background-size: 3px 20px;
  */
}

.foot-table .row .col:last-child{
  background: none;
}
